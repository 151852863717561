var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.saveProduct)}}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-row',[_c('b-col',{staticClass:"mb-3",attrs:{"md":"6"}},[_c('input-form',{attrs:{"placeholder":"Type Product name...","validate":'required|max:25',"name":"name","label":'Product Name',"limit":25},model:{value:(_vm.product.name),callback:function ($$v) {_vm.$set(_vm.product, "name", $$v)},expression:"product.name"}})],1),_c('b-col',{staticClass:"mb-3",attrs:{"md":"6"}},[_c('input-form',{attrs:{"placeholder":"Type title...","validate":'required|max:20',"name":"title","label":'Title',"limit":20},model:{value:(_vm.product.title),callback:function ($$v) {_vm.$set(_vm.product, "title", $$v)},expression:"product.title"}})],1)],1),_c('b-row',[_c('b-col',{staticClass:"mb-3",attrs:{"md":"4"}},[_c('b-form-group',{staticClass:"position-relative",attrs:{"label":"Price","label-for":"Price"}},[_c('validation-provider',{staticClass:"flex-grow-1",attrs:{"name":"Price","rules":'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"append":"EGP"}},[_c('b-form-input',{class:[{ 'is-invalid': errors.length > 0 }],attrs:{"placeholder":'000.00'},model:{value:(_vm.product.price_egp),callback:function ($$v) {_vm.$set(_vm.product, "price_egp", $$v)},expression:"product.price_egp"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"mb-3 d-flex justify-content-center align-items-center pt-2",attrs:{"md":"4"}},[_c('b-form-checkbox',{staticClass:"custom-checkbox-color-check",attrs:{"type":"checkbox","color":"warning"},model:{value:(_vm.selectedEGP),callback:function ($$v) {_vm.selectedEGP=$$v},expression:"selectedEGP"}},[_c('span',{staticClass:"text-secondary font-size-12"},[_vm._v("Discounted Price")])])],1),_c('b-col',{staticClass:"mb-3",attrs:{"md":"4"}},[_c('b-form-group',{staticClass:"position-relative",attrs:{"label":"Discounted price","label-for":"Discounted price"}},[_c('validation-provider',{staticClass:"flex-grow-1",attrs:{"name":"price_egp","rules":((_vm.selectedEGP ? 'required': '') + "|numeric|between:0," + (_vm.product.price_egp))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"append":"EGP"}},[_c('b-form-input',{class:[{ 'is-invalid': errors.length > 0 }],attrs:{"id":"mm","disabled":!_vm.selectedEGP,"placeholder":'000.0'},model:{value:(_vm.product.discount_price_egp),callback:function ($$v) {_vm.$set(_vm.product, "discount_price_egp", $$v)},expression:"product.discount_price_egp"}})],1),(!_vm.product.discount_price_egp)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e(),(Number(_vm.product.discount_price_egp) > Number(_vm.product.price_egp))?_c('small',{staticClass:"text-danger"},[_vm._v(" More than price ")]):_vm._e()]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"mb-3",attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Description","label-for":"Description"}},[_c('ValidationProvider',{ref:"Description",attrs:{"name":"description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{class:(errors.length >
                           0 ? ' is-invalid' : ''),attrs:{"placeholder":"Description...","rows":"2"},model:{value:(_vm.product.description),callback:function ($$v) {_vm.$set(_vm.product, "description", $$v)},expression:"product.description"}}),_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1)],1)],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-row',[_c('b-col',[_c('cropper-images',{attrs:{"label":"Upload Product Photos","progressLoading":_vm.loadingGallery,"removeLoadingUi":_vm.removeLoadingUi,"images":_vm.allImages,"type":"product_image"},on:{"cropper-save":_vm.saveGalleryImage,"remove-image":_vm.removeGalleryImage}})],1)],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"mt-4",attrs:{"md":"12"}},[(_vm.typeOfModal == 'add')?_c('div',{staticClass:"d-flex justify-content-center"},[(!_vm.requestLoading)?_c('b-button',{staticClass:"button-orange-modal",attrs:{"type":"submit"}},[_c('i',{staticClass:"las la-plus"})]):_c('b-button',{staticClass:"button-orange-modal"},[_c('spinner-loading')],1)],1):_c('div',{staticClass:"d-flex justify-content-center"},[(!_vm.requestLoading)?_c('b-button',{staticClass:"button-blue-modal",attrs:{"type":"submit"}},[_c('i',{staticClass:"las la-pen"})]):_c('b-button',{staticClass:"button-blue-modal"},[_c('spinner-loading')],1)],1)])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }